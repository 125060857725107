<template>
    <div style="background:white" class="h-100">
        <v-main>
            <v-container>
                <v-row dense>
                    <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                        <v-row>
                            <v-col cols="12">
                                <h3>KOPERASI BINA UMATI FADHAL UMAR (KBUFU)</h3>
                                <p>“Menjadi Koperasi yang terpercaya, bermanfaat, sukses, tangguh dan memberikan pelayanan prima kepada anggota koperasi dan masyarakat dalam meningkatkan pendapatan koperasi dan kesejahteraan anggota”</p>
                            </v-col>
                            <v-col cols="12" class="mt-3">
                                <div style="text-align:left;font-size:18px;">
                                    <v-sheet
                                        height="50"
                                        class="pa-3 mb-3"
                                        color="orange lighten-5"
                                        @click="scrollTo('pengantar')">
                                        Pengantar

                                    </v-sheet>
                                    <v-sheet
                                        height="50"
                                        class="pa-3 mb-3"
                                        color="orange lighten-5"
                                        @click="scrollTo('profil')">
                                        Profil KBUFU
                                    </v-sheet>
                                    <v-sheet
                                        height="50"
                                        class="pa-3"
                                        color="orange lighten-5"
                                        @click="scrollTo('bisnis')">
                                        Bisnis KBUFU
                                    </v-sheet>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" md="6" sm="12" xs="12" class="d-none d-sm-block">
                        <img alt="Bufu logo" src="../assets/bufu-logo-trans.png" width="300">
                    </v-col>
                </v-row>
                <v-row ref="pengantar" v-show="showPengantar">
                    <v-col cols="12">
                        <div style="text-align:left;">
                            <h5>Pengantar</h5>
                            <p>
                                Alhamdullilah dengan karunia Allah SWT. koperasi di lingkungan Jemaat sudah berdiri dan telah memiliki kekuatan hukum sehingga aspek legalnya terpenuhi dan bisa beraktivitas sesuai dengan rencana pendiriannya. Pendirian koperasi ini disambut baik dan telah mendapat restu dari Amir Nasional Jemaat Ahmadiyah Indonesia dengan nama koperasi BINA UMATI FADHAL UMAR. 
                            </p>
                            <p>
                                Kesejahteraan antara lain akan tercapai dengan mendirikan koperasi, maka dalam kaitan ini, koperasi hadir dalam mendorong program-program Umur Ammah untuk membangun kesejahteraan bersama anggota Jemaat melalui koperasi yang dikelola oleh para pengurus dengan semangat melayani, mampu bekerja sama, amanah, dan membangun tujuan luhur dalam meningkatkan kesejahteraan anggotanya.
                            </p>
                            <p>
                                Menyikapi perkembangan kekinian, bahkan di era yang semakin banyak ketergantungan dan saling membutuhkan tak berlebihan kiranya kami mengajak para anggota Jemaat untuk menjadi anggota koperasi. Kami beranggapan BERKOPERASI di KALANGAN JEMAAT BERPOTENSI BESAR untuk maju dan terus berkembang. Mari kita dukung kehadirannya dan kita awasi pengelolaannya serta majukan bersama-sama dalam suasana dan semangat persaudaraan sebagai ciri Jamaah Islami melalui koperasi.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <v-row ref="profil" v-show="showProfil">
                    <v-col cols="12">
                        <div style="text-align:left;">
                            <h5>Profil KBUFU</h5>
                            <p>
                                <ol>
                                    <li>
                                        <b>Visi</b>
                                        <p>Visi Koperasi adalah Menjadi Koperasi yang terpercaya, bermanfaat, sukses, tangguh dan memberikan pelayanan prima kepada anggota koperasi dan masyarakat dalam meningkatkan pendapatan koperasi dan kesejahteraan anggota</p>
                                    </li>
                                    <li>
                                        <b>Misi</b>
                                        <ol type="a">
                                            <li>Menjalin silaturahmi antara pengurus dan anggota dalam menjalankan koperasi agar tetap tangguh dan berhasil</li>
                                            <li>Mengusahakan penyediaan kebutuhan pokok anggota dan masyarakat berupa barang-barang primer dan sekunder</li>
                                            <li>Membantu anggota dalam bermitra usaha dan memasarkan barang atau hasil produksi yang berkualitas, dibutuhkan oleh masyarakat dan harganya kompetitif</li>
                                            <li>Meningkatkan pelayanan yang prima kepada anggota koperasi dan pelanggan lainnya</li>
                                            <li>Meningkatkan peran serta anggota koperasi dalam pengembangan koperasi ke arah yang lebih tangguh dan maju dengan prinsip efisien, efektif dan produktif</li>
                                            <li>Mengelola manajemen koperasi dengan profesional</li>
                                            <li>Meningkatkan jejaring kemitraan dengan anggota, koperasi atau pihak lain dalam pembinaan dan pengembangan koperasi di dalam negeri maupun di luar negeri</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Tujuan</b>
                                        <ol type="a">
                                            <li>Meningkatkan silaturahmi, kebersamaan dan gotong royong di antara sesama anggota koperasi dalam mewujudkan kesejahteraan ekonomi anggota dan masyarakat.</li>
                                            <li>Menjadi wadah kegiatan usaha bersama yang dikelola secara profesional dengan efisien, efektif dan produktif dalam meningkatkan pendapatan koperasi</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Jenis Simpanan</b>
                                        <ol type="a">
                                            <li>Simpanan Pokok (100rb hanya 1x di awal)</li>
                                            <li>Simpanan Wajib (minimal 25rb/bulan)</li>
                                            <li>Simpanan Lainnya (Kurban, Umrah, Jalsah)</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Legalitas</b>
                                        <ol type="a">
                                            <li>Sertifikat Menteri Koperasi dan UKM</li>
                                            <li>Nomor Induk Koperasi (NIK) 3671010070003</li>
                                            <li>Nomor Induk Berusaha (NIB) 0220601950035</li>
                                            <li>Akta Notaris No.07 Tanggal 24 Agustus 2020</li>
                                            <li>SK Kemenkumham Republik Indonesia No. AHU-0005027.AH.01.26.TAHUN 2020</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Penasihat</b>
                                        <ol type="a">
                                            <li>Ketua Penasihat = Mln H. Abdul Basith, Shd</li>
                                            <li>Wakil Ketua = Bagus Rachman, S.E., M.Ec.</li>
                                            <li>Anggota = Gunawan Wardi</li>
                                            <li>Anggota = Ir. H. Setyo Soemito</li>
                                            <li>Anggota = Irvan Yanur Yana</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Pengawas</b>
                                        <ol type="a">
                                            <li>Ketua Pengawas = Drs. Taufik Ahmad, M.Si.</li>
                                            <li>Anggota = Lukman Boenjamin, S.E., M.A.</li>
                                            <li>Anggota = Fadlil Ahmad, S.E.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Pengurus</b>
                                        <ol type="a">
                                            <li>Ketua Umum = Drs. A. Gani Setiawan, M.M.</li>
                                            <li>Ketua 1 = Mubarak Ahmad Kamil, S.E.</li>
                                            <li>Ketua 2 = Ir. Rudy Raditya Setiadji</li>
                                            <li>Sekretaris = Didim Dimyati</li>
                                            <li>Wakil Sekretaris = Kamal Ahmad, S.E</li>
                                            <li>Bendahara = Jayadih, M.Kom</li>
                                            <li>Wakil Bendahara = Drs. Dedi Herdiana, M.M.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Bentuk dan Jenis Koperasi</b>
                                        <ol type="a">
                                            <li>Bentuk Koperasi = Primer Nasional</li>
                                            <li>Jenis Koperasi = Konsumen</li>
                                            <li>Kelompok Koperasi = Koperasi Serba Usaha</li>
                                            <li>Sektor Usaha = Perdagangan Besar dan Eceran</li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <v-row ref="bisnis" v-show="showBisnis">
                    <v-col cols="12">
                        <div style="text-align:left;">
                            <h5>Bisnis KBUFU</h5>
                            <p>KBUFU telah membuka <b>K-Bufu Store</b> di Market Place <code>Tokopedia</code>, <code>Lazada</code>, <code>Blibli</code>, <code>Shopee</code> dan <code>Bukalapak</code> sebagai sarana untuk memasarkan produk-produk anggota koperasi, untuk lebih lanjut silahkan <a href="https://campsite.bio/kbufu" target="_blank" style="text-decoration:none">cek disini</a></p>
                            <p>Untuk mendaftarkan produk yang akan dipasarkan, silahkan mendaftar <a href="http://bit.ly/daftarkbufustore" target="_blank" style="text-decoration:none">disini</a></p>
                            <p><b>Kami menyediakan pembayaran online</b></p>
                            <v-img :width="'500'" src="../assets/profile/online-pay.jpg"></v-img>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div style="text-align:left;">
                            <p><b>Produk kami</b></p>
                            <v-row>
                                <template
                                    v-for="(item, index) in slideSembako">
                                    <v-col cols="12" xl="4" md="4" sm="12" xs="12" :key="index">
                                        <v-img :width="'500'" :src="require('../assets/profile/'+item.img)"></v-img>
                                    </v-col>
                                </template>
                            </v-row>
                            <!--<slider class="mx-auto" animation="fade" :width="$root.isMobile?'':'50%'" :height="$root.isMobile?'380px':'500px'" :control-btn="false" :stopOnHover="true">
                            <slider-item
                                v-for="(i, index) in slideSembako"
                                :key="index"
                                :style="i.style"
                            >
                                <div align="center">
                                    <v-img :width="$root.isMobile?'100%':'500'" :src="require('../assets/profile/'+i.img)"></v-img>
                                </div>
                            </slider-item>
                            </slider>-->
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
// import { Slider, SliderItem } from 'vue-easy-slider'
export default {
    components: {
        // Slider,
        // SliderItem,
    },
    data() {
        return {
            showPengantar: false,
            showProfil: false,
            showBisnis: false,
            slideSembako: [
                {img: 'bansos-1.jpg'},
                {img: 'bansos-2.jpg'},
                {img: 'sembako.jpg'},
                {img: 'qurban-1.jpg'},
                {img: 'qurban-2.jpg'},
                {img: 'alkes.jpg'},
                {img: 'jahe.jpg'},
            ]
        }
    },
    methods: {
        scrollTo(refName) {
            this.clearShow()
            this.defineShow(refName)
            setTimeout(() => {
                var element = this.$refs[refName];
                var top = element.offsetTop;
                window.scrollTo({
                    top:top,
                    left: 0,
                    behavior: 'smooth'
                });
            },50)
        },
        clearShow() {
            this.showPengantar = false
            this.showProfil = false
            this.showBisnis = false
        },
        defineShow(refName) {
            if (refName === 'pengantar') this.showPengantar = true
            if (refName === 'profil') this.showProfil = true
            if (refName === 'bisnis') this.showBisnis = true
        },
        
    },
}
</script>